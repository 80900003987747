<template>
	<div class="container">
		<div class="search-category-wrap">
			<div class="search-category">
				<button v-for="(item, idx) in categoryList" :key="idx" type="button" class="search-category-button" :class="{ 'is-active': resultIdx === idx }" @click.prevent="showResults(item.type, idx)">
					<span class="text">{{ item.title }}</span>
					<strong>{{ item.nums }}</strong>
					<span class="unit">건</span>
				</button>
			</div>
		</div>
		<!-- s :전체 검색 결과 -->
		<div v-if="listType === 'total'" class="page-component" :class="{ ' is-search-total': listType === 'total' }">
			<section v-for="(item, idx) in categoryList" v-if="idx > 0" :key="idx" class="section-result">
				<header class="section-header">
					<h3 class="header-title">
						{{ categoryList[idx].title }} <span class="text-strong">{{ categoryList[idx].nums }}</span
						>건
					</h3>
					<a href="" class="link-more">더보기</a>
				</header>
				<!-- [DEV]검색결과가 없는 경우 -->
				<div v-if="categoryList[idx].nums === 0" class="no-results-found">
					<p class="text">검색결과가 없습니다</p>
				</div>
				<!-- //[DEV]검색결과가 없는 경우 -->
				<!-- 이미지 썸네일 리스트 -->
				<ul v-else-if="categoryList[idx].nums > 0 && categoryList[idx].type == 'thumb'" class="search-list">
					<li>
						<a href="#" class="search-item">
							<div class="item-col">
								<span class="search-item-thumb">
									<img src="@/assets/img/@tmp/tmp_image.jpg" alt="" />
								</span>
							</div>
							<div class="item-col">
								<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							</div>
						</a>
					</li>
					<li>
						<a href="#" class="search-item">
							<div class="item-col">
								<span class="search-item-thumb">
									<img src="@/assets/img/@tmp/tmp_image.jpg" alt="" />
								</span>
							</div>
							<div class="item-col">
								<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							</div>
						</a>
					</li>
					<li>
						<a href="#" class="search-item">
							<div class="item-col">
								<span class="search-item-thumb">
									<img src="@/assets/img/@tmp/tmp_image.jpg" alt="" />
								</span>
							</div>
							<div class="item-col">
								<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							</div>
						</a>
					</li>
				</ul>
				<!-- //이미지 썸네일 리스트 -->
				<!-- 텍스트 리스트 -->
				<ul v-else-if="categoryList[idx].nums > 0 && categoryList[idx].type == 'text'" class="search-list">
					<li>
						<a href="#" class="search-item--text">
							<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							<p class="search-item-desc">본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다.</p>
						</a>
					</li>
					<li>
						<a href="#" class="search-item--text">
							<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							<p class="search-item-desc">본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다.</p>
						</a>
					</li>
					<li>
						<a href="#" class="search-item--text">
							<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							<p class="search-item-desc">본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다.</p>
						</a>
					</li>
				</ul>
				<!-- //텍스트 리스트 -->
			</section>
		</div>
		<!-- e :전체 검색 결과 -->

		<!-- s : 검색 결과 -->
		<div v-else class="page-component">
			<section class="section-result">
				<header class="section-header">
					<h3 class="header-title">
						{{ categoryList[resultIdx].title }} <span class="text-strong">{{ categoryList[resultIdx].nums }}</span
						>건
					</h3>
				</header>
				<!-- [DEV]검색결과가 없는 경우 -->
				<div v-if="categoryList[resultIdx].nums === 0" class="no-results-found">
					<p class="text">검색결과가 없습니다</p>
				</div>
				<!-- //[DEV]검색결과가 없는 경우 -->
				<!-- 이미지 썸네일 리스트 -->
				<ul v-else-if="categoryList[resultIdx].nums > 0 && listType == 'thumb'" class="search-list">
					<li>
						<a href="#" class="search-item">
							<div class="item-col">
								<span class="search-item-thumb">
									<img src="@/assets/img/@tmp/tmp_image.jpg" alt="" />
								</span>
							</div>
							<div class="item-col">
								<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							</div>
						</a>
					</li>
					<li>
						<a href="#" class="search-item">
							<div class="item-col">
								<span class="search-item-thumb">
									<img src="@/assets/img/@tmp/tmp_image.jpg" alt="" />
								</span>
							</div>
							<div class="item-col">
								<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							</div>
						</a>
					</li>
					<li>
						<a href="#" class="search-item">
							<div class="item-col">
								<span class="search-item-thumb">
									<img src="@/assets/img/@tmp/tmp_image.jpg" alt="" />
								</span>
							</div>
							<div class="item-col">
								<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							</div>
						</a>
					</li>
					<li>
						<a href="#" class="search-item">
							<div class="item-col">
								<span class="search-item-thumb">
									<img src="@/assets/img/@tmp/tmp_image.jpg" alt="" />
								</span>
							</div>
							<div class="item-col">
								<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							</div>
						</a>
					</li>
					<li>
						<a href="#" class="search-item">
							<div class="item-col">
								<span class="search-item-thumb">
									<img src="@/assets/img/@tmp/tmp_image.jpg" alt="" />
								</span>
							</div>
							<div class="item-col">
								<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							</div>
						</a>
					</li>
					<li>
						<a href="#" class="search-item">
							<div class="item-col">
								<span class="search-item-thumb">
									<img src="@/assets/img/@tmp/tmp_image.jpg" alt="" />
								</span>
							</div>
							<div class="item-col">
								<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							</div>
						</a>
					</li>
					<li>
						<a href="#" class="search-item">
							<div class="item-col">
								<span class="search-item-thumb">
									<img src="@/assets/img/@tmp/tmp_image.jpg" alt="" />
								</span>
							</div>
							<div class="item-col">
								<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							</div>
						</a>
					</li>
					<li>
						<a href="#" class="search-item">
							<div class="item-col">
								<span class="search-item-thumb">
									<img src="@/assets/img/@tmp/tmp_image.jpg" alt="" />
								</span>
							</div>
							<div class="item-col">
								<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							</div>
						</a>
					</li>
					<li>
						<a href="#" class="search-item">
							<div class="item-col">
								<span class="search-item-thumb">
									<img src="@/assets/img/@tmp/tmp_image.jpg" alt="" />
								</span>
							</div>
							<div class="item-col">
								<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							</div>
						</a>
					</li>
				</ul>
				<!-- //이미지 썸네일 리스트 -->
				<!-- 텍스트 리스트 -->
				<ul v-else-if="categoryList[resultIdx].nums > 0 && listType == 'text'" class="search-list">
					<li>
						<a href="#" class="search-item--text">
							<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							<p class="search-item-desc">본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다.</p>
						</a>
					</li>
					<li>
						<a href="#" class="search-item--text">
							<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							<p class="search-item-desc">본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다.</p>
						</a>
					</li>
					<li>
						<a href="#" class="search-item--text">
							<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							<p class="search-item-desc">본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다.</p>
						</a>
					</li>
					<li>
						<a href="#" class="search-item--text">
							<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							<p class="search-item-desc">본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다.</p>
						</a>
					</li>
					<li>
						<a href="#" class="search-item--text">
							<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							<p class="search-item-desc">본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다.</p>
						</a>
					</li>
					<li>
						<a href="#" class="search-item--text">
							<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							<p class="search-item-desc">본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다.</p>
						</a>
					</li>
					<li>
						<a href="#" class="search-item--text">
							<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							<p class="search-item-desc">본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다.</p>
						</a>
					</li>
					<li>
						<a href="#" class="search-item--text">
							<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							<p class="search-item-desc">본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다.</p>
						</a>
					</li>
					<li>
						<a href="#" class="search-item--text">
							<strong class="search-item-title">제목이 여기 들어갑니다.</strong>
							<p class="search-item-desc">본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다. 본문 내용이 들어갑니다.</p>
						</a>
					</li>
				</ul>
				<!-- //텍스트 리스트 -->
				<!-- pagination -->
				<portal-pagination page-name="search2" :page-no="pageNo" :page-size="pageSize" :page-list-size="pageListSize" :total-count="categoryList[resultIdx].nums" :query="queries"></portal-pagination>
				<!-- //pagination -->
			</section>
		</div>
		<!-- e : 검색 결과 -->
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import PortalPagination from '@/components/PortalPagination';
import { getCheckItems, setPaging, queryToValue } from '@/assets/js/utils';
export default {
	name: 'Results',
	components: {
		PortalPagination,
	},
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile']),
		queries() {
			const result = {};
			return result;
		},
	},
	props: {},
	mounted() {},
	data: () => ({
		totalCount: 100,
		pageSize: 10,
		pageListSize: 5,
		pageNo: 1,
		categoryList: [
			{
				title: '전체',
				type: 'total',
				nums: 100,
			},
			{
				title: '판로TV',
				type: 'thumb',
				nums: 0,
			},
			{
				title: '중소벤처기업부 지원사업',
				type: 'text',
				nums: 20,
			},
			{
				title: '지자체 지원사업',
				type: 'text',
				nums: 30,
			},
			{
				title: '소상공인 포스트',
				type: 'thumb',
				nums: 40,
			},
			{
				title: '소상공인 뉴스',
				type: 'thumb',
				nums: 50,
			},
			{
				title: '소상공인 이야기',
				type: 'thumb',
				nums: 60,
			},
			{
				title: '소상공인 인터뷰',
				type: 'thumb',
				nums: 70,
			},
			{
				title: '온라인 진출 지원사례',
				type: 'thumb',
				nums: 80,
			},
			{
				title: '공지사항',
				type: 'text',
				nums: 90,
			},
		],
		listType: 'total',
		resultIdx: 0,
	}),
	methods: {
		showResults(type, idx) {
			this.listType = type;
			this.resultIdx = idx;
		},
	},
};
</script>
