<template>
	<div class="gachi-search2" data-search="results">
		<search :search-data="getSearch" />
		<div class="search-results">
			<results :headers="headers" :items="items"> </results>
		</div>
	</div>
</template>

<script>
import Results from '../../../components/search/Results2';
import { ACT_GET_SEARCH_LIST } from '../../../store/_act_consts';
import { getItems, lengthCheck } from '../../../assets/js/utils';
import Search from '../../../components/search/Search2';

export default {
	name: 'SearchResult',
	components: {
		Search,
		Results,
	},
	data: () => ({
		searchTerm: '',
		headers: [
			{
				num: 0,
				sectionClass: 'section-community',
				headerTitleText: '온라인 진출 지원사례',
				isThumbnail: true,
				column: 'USER_NM',
				column2: 'COMUN_BLTARTCL_TIT',
				img: 'THMBNL_IMG_PT_URL',
				paramKey: 'comunBltartclId',
				paramVal: 'COMUN_BLTARTCL_ID',
				routerName: ['Community', 'CommunityView'],
			},
			{
				num: 1,
				sectionClass: 'section-creator',
				headerTitleText: '맞춤 인플루언서 찾기',
				isCreator: true,
				column: 'FTHLD_NM',
				certCtrYn: 'CERT_CTR_YN',
				tags: ['FTHLD_TAG_1', 'FTHLD_TAG_2', 'FTHLD_TAG_3'],
				img: 'RPRS_IMG_PT_URL',
				paramKey: 'ctrFthldId',
				paramVal: 'CTR_FTHLD_ID',
				routerName: ['Foothold', 'FootholdView'],
			},
			{
				num: 2,
				sectionClass: '',
				headerTitleText: '중소벤처기업부',
				isSprt: true,
				column: 'SPRT_PATTN',
				column2: 'SPRT_BIZ_NM',
				aplyUrl: 'APLY_URL',
				routerName: ['Support', 'Support'],
			},
			// {
			//   num: 3, sectionClass:'section-campaign', headerTitleText:'크리에이터 매칭 캠페인', isThumbnail: true,
			//   column: 'SUPP_NM', column2: 'MATCH_CAMPN_NM', paramKey: 'matchCampnId', paramVal: 'MATCH_CAMPN_ID', routerName: ['Campaign', 'CampaignView']
			// },
			{
				num: 4,
				sectionClass: 'section-notice',
				headerTitleText: '콘텐츠',
				isThumbnail: true,
				column: 'PLYGRND_CTGR',
				column2: 'PLYGRND_TIT',
				img: 'RPRS_IMG_PT_URL',
				paramKey: 'plygrndId',
				paramVal: 'PLYGRND_ID',
				routerName: ['', 'View'],
				plygrndGb: 'PLYGRND_GB',
			},
			{
				num: 5,
				sectionClass: 'section-notice',
				headerTitleText: '소상공인 포스트',
				isThumbnail: true,
				column: 'ISSUE_SML_TIT',
				column2: 'ISSUE_TIT',
				img: 'RPRS_IMG_PT_URL',
				paramKey: 'issueId',
				paramVal: 'ISSUE_ID',
				routerName: ['Issue', 'IssueView'],
			},
		],
		items: [],
	}),
	created() {
		this.getSearch();
	},
	methods: {
		getSearch() {
			this.searchTerm = this.$route.query.searchTerm;

			this.$store
				.dispatch(`common/${ACT_GET_SEARCH_LIST}`, this.searchTerm)
				.then((res) => {
					if (lengthCheck(res)) {
						this.items = getItems(res);
					}
				})
				.catch((e) => {
					console.error(e);
				});
		},
	},
};
</script>
